import React, { Component } from 'react';
import ApolloClient from "apollo-boost";
import { ApolloProvider } from "react-apollo";
import { Query } from "react-apollo";
import gql from "graphql-tag";
//import 'antd/dist/antd.css';
import { Spin, Empty, Icon } from 'antd';
import { Steps, Row, Col, Card, Button, notification } from 'antd';

const client = new ApolloClient({
  uri: "https://europe-west1-leasecalculator-197808.cloudfunctions.net/api"
});

const Step = Steps.Step;

const Error = ({ error }) => {
  notification.error({
    message: 'Sorry something went wrong',
    description: error.message,
    placement: 'topRight',
    duration: 0,
  });

  return (
    <Row align='middle' justify='center' type='flex' style={{ height: '100vh' }} >
      <Col span={12} style={{ textAlign: 'center' }} >
        <Button shape="circle" icon="reload" onClick={() => window.location.reload()} />
      </Col>
    </Row>
  )
}

class Application extends Component {
  render() {
    return (
      <ApolloProvider client={client}>
        <Query
          query={gql`
            {
              order(generatedId: "${this.props.id}") {
                id
                order_number
                order_status {
                  name
                  code
                }
                bike {
                  brand
                  model
                }
                quotation {
                  dealer {
                    name
                  }
                  employee {
                    name
                    company {
                      name
                    }
                  }
                }
              }
            }
          `}
        >
          {({ loading, error, data }) => {
            if (loading) return (
              <Row align='middle' justify='center' type='flex' style={{ height: '100vh' }} >
                <Col span={12} style={{ textAlign: 'center' }} >
                  <Spin />
                </Col>
              </Row>
            );

            if (error) return <Error error={error} />

            if (!data.order) return (
              <Row align='middle' justify='center' type='flex' style={{ height: '100vh' }} >
                <Col span={12} style={{ textAlign: 'center' }} >
                  <Empty description={
                    <span>
                      order not found
                    </span>
                  } />
                </Col>
              </Row>
            )

            if (data.order.order_status.code === 'CANCELLED') {
              return (
                <div>
                  <Row type="flex" justify="center">
                    <Col>
                      <Card style={{ width: 320 }} className="card">
                        <h2>Info</h2>
                        <Row>
                          <Col span={24}>
                            <Row gutter={8}>
                              <Col span={6}>
                                <p style={{ textAlign: 'right' }}>Name:</p>
                              </Col>
                              <Col span={18} style={{ wordWrap: 'break-word' }}>
                                <p style={{ fontWeight: 600 }}>{data.order.quotation.employee.name}</p>
                              </Col>
                            </Row>
                          </Col>
                          <Col span={24}>
                            <Row gutter={8}>
                              <Col span={6}>
                                <p style={{ textAlign: 'right' }}>Company:</p>
                              </Col>
                              <Col span={18} style={{ wordWrap: 'break-word' }}>
                                <p style={{ fontWeight: 600 }}>{data.order.quotation.employee.company.name}</p>
                              </Col>
                            </Row>
                          </Col>
                          <Col span={24}>
                            <Row gutter={8}>
                              <Col span={6}>
                                <p style={{ textAlign: 'right' }}>Dealer:</p>
                              </Col>
                              <Col span={18} style={{ wordWrap: 'break-word' }}>
                                <p style={{ fontWeight: 600 }}>{data.order.quotation.dealer.name}</p>
                              </Col>
                            </Row>
                          </Col>
                          <Col span={24}>
                            <Row gutter={8}>
                              <Col span={6}>
                                <p style={{ textAlign: 'right' }}>Brand:</p>
                              </Col>
                              <Col span={18} style={{ wordWrap: 'break-word' }}>
                                <p style={{ fontWeight: 600 }}>{data.order.bike ? data.order.bike.brand : '...'}</p>
                              </Col>
                            </Row>
                          </Col>
                          <Col span={24}>
                            <Row gutter={8}>
                              <Col span={6}>
                                <p style={{ textAlign: 'right' }}>Model:</p>
                              </Col>
                              <Col span={18} style={{ wordWrap: 'break-word' }}>
                                <p style={{ fontWeight: 600 }}>{data.order.bike ? data.order.bike.model : '...'}</p>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  </Row>
                  <Row type="flex" justify="center">
                    <Col>
                      <Card style={{ width: 320 }} className="card">
                        <h2>Status</h2>
                        <Empty description={<span>Order refused</span>} image={<Icon type='close' style={{ fontSize: 48, color: 'red' }} />} >
                        </Empty>
                      </Card>
                    </Col>
                  </Row>
                </div>
              )
            }

            switch (data.order.order_status.code) {
              case 'NEW':
                data.order.order_status.step = 0;
                break;
              case 'SIGNED':
                data.order.order_status.step = 1;
                break;
              case 'SEND_TO_DECIDER':
                data.order.order_status.step = 2;
                break;
              case 'APPROVED':
                data.order.order_status.step = 3;
                break;
              case 'DELIVERABLE':
                data.order.order_status.step = 4;
                break;
              case 'DELIVERED':
                data.order.order_status.step = 5;
                break;
              default:
                data.order.order_status.step = 0;
            }

            return (
              <div>
                <Row type="flex" justify="center">
                  <Col>
                    <Card style={{ width: 320 }} className="card">
                      <h2>Info</h2>
                      <Row>
                        <Col span={24}>
                          <Row gutter={8}>
                            <Col span={6}>
                              <p style={{ textAlign: 'right' }}>Name:</p>
                            </Col>
                            <Col span={18} style={{ wordWrap: 'break-word' }}>
                              <p style={{ fontWeight: 600 }}>{data.order.quotation.employee.name}</p>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={24}>
                          <Row gutter={8}>
                            <Col span={6}>
                              <p style={{ textAlign: 'right' }}>Company:</p>
                            </Col>
                            <Col span={18} style={{ wordWrap: 'break-word' }}>
                              <p style={{ fontWeight: 600 }}>{data.order.quotation.employee.company.name}</p>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={24}>
                          <Row gutter={8}>
                            <Col span={6}>
                              <p style={{ textAlign: 'right' }}>Dealer:</p>
                            </Col>
                            <Col span={18} style={{ wordWrap: 'break-word' }}>
                              <p style={{ fontWeight: 600 }}>{data.order.quotation.dealer.name}</p>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={24}>
                          <Row gutter={8}>
                            <Col span={6}>
                              <p style={{ textAlign: 'right' }}>Brand:</p>
                            </Col>
                            <Col span={18} style={{ wordWrap: 'break-word' }}>
                              <p style={{ fontWeight: 600 }}>{data.order.bike ? data.order.bike.brand : '...'}</p>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={24}>
                          <Row gutter={8}>
                            <Col span={6}>
                              <p style={{ textAlign: 'right' }}>Model:</p>
                            </Col>
                            <Col span={18} style={{ wordWrap: 'break-word' }}>
                              <p style={{ fontWeight: 600 }}>{data.order.bike ? data.order.bike.model : '...'}</p>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={24}>
                          <Row gutter={8}>
                            <Col span={6}>
                              <p style={{ textAlign: 'right' }}>Number:</p>
                            </Col>
                            <Col span={18} style={{ wordWrap: 'break-word' }}>
                              <p style={{ fontWeight: 600 }}>{data.order.order_number || '...'}</p>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>

                <Row type="flex" justify="center">
                  <Col>
                    <Card style={{ width: 320 }} className="card">
                      <h2>Status</h2>
                      <Steps progressDot direction="vertical" current={data.order.order_status.step}>
                        <Step title="New" description="New calculation" />
                        <Step title="Signed" description="Calculation signed by employee" />
                        <Step title="Sent to decision maker" description="Waiting for approval" />
                        <Step title="Approved" description="Decision maker approved the order" />
                        <Step title="Ready" description="Bike is ready for delivery" />
                        <Step title="Delivered" description="Bike delivered" />
                      </Steps>
                    </Card>
                  </Col>
                </Row>

              </div>);
          }}
        </Query>
      </ApolloProvider>
    );
  }
}

export default Application;
